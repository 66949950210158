.custom-header-style {
    height: var(--header-height);
    margin-bottom: var(--header-mb);
}

.header-position {
    position: var(--header-position);
}

.header-inner-position {
    position: var(--header-inner-position);
}

.header-top {
    top: var(--header-top);
}

.content-offset {
    height: var(--content-offset);
}
.avatar-border {
    opacity: var(--avatar-border-opacity, 0);
    transform: var(--avatar-border-transform);
}

.avatar-image-transform {
    transform: var(--avatar-image-transform);
}

.avatar-image-color {
    color: green;
}

.image-size {
    width: 4rem;
    height: auto; /* maintain aspect ratio, or you can set height: 4rem; to force the height */
}

.visually-hidden {
    position: fixed;
    top: 1px;
    left: 1px;
    width: 1px;
    height: 0;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    display: none;
}
